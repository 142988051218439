import { Box } from '@material-ui/core'
import { Video } from 'common/components/atoms/Video'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { AlertsProps } from 'common/components/organisms/Alerts'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import {
    CampaignJumbotron,
    CampaignJumbotronProps
} from 'common/components/organisms/CampaignJumbotron'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { useDesktop } from 'common/hooks/useDesktop'
import { CampaignSection } from './CampaignSection'

import React from 'react'

export interface CampaignLandingRegularProps {
    layout: LayoutProps
    alerts: AlertsProps
    video: any
    campaignFeature: CampaignFeatureProps
    campaignFeatures: FeatureListProps[]
    featureListHeading: string
    featureListText: string
    jumbotron: CampaignJumbotronProps
}

export const CampaignLandingRegular: React.FC<CampaignLandingRegularProps> = ({
    alerts,
    layout,
    campaignFeatures,
    featureListHeading,
    featureListText,
    jumbotron,
    campaignFeature,
    video
}) => {
    const desktop = useDesktop()
    return (
        <Layout {...layout}>
            <CampaignJumbotron {...jumbotron} />
            <Wrapper style={{ background: '#fff' }}>
                {campaignFeatures &&
                    campaignFeatures.map(
                        (feature: FeatureListProps, idx: number) => (
                            <Box key={idx}>
                                <Box mx={[0, 0, 24]} mt={10}>
                                    <Text
                                        box={{
                                            mb: 6,
                                            textAlign: desktop
                                                ? 'center'
                                                : undefined
                                        }}
                                        label={featureListHeading}
                                        variant={desktop ? 'h4' : 'h2'}
                                    />
                                    <Text
                                        box={{
                                            textAlign: desktop
                                                ? 'center'
                                                : undefined
                                        }}
                                        color='textSecondary'
                                        html
                                        label={featureListText}
                                        variant='subtitle1'
                                    />
                                </Box>
                                <FeatureList {...feature} />
                                <Spacer />
                            </Box>
                        )
                    )}

                <Video {...video} />
            </Wrapper>
            <CampaignSection
                campaignFeature={campaignFeature}
                alerts={alerts}
            />
        </Layout>
    )
}
