import { Box, Grid, Hidden } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import { useDpr } from 'common/hooks/useDpr'
import React from 'react'
import {
    JumbotronCallToAction,
    JumbotronCallToActionProps
} from '../Jumbotron/JumbotronCallToAction'

export interface CampaignJumbotronProps {
    image?: string
    subtitle?: string
    text: string
    title: string
    callToAction?: JumbotronCallToActionProps
}

export const CampaignJumbotron: React.FC<CampaignJumbotronProps> = ({
    image,
    callToAction,
    subtitle,
    text,
    title
}) => {
    const dpr = useDpr()
    const desktop = useDesktop()

    return (
        <Box component='section'>
            <Box
                position='absolute'
                width='100%'
                height='100%'
                top={0}
                zIndex={-1}
            />
            <Box data-testid='CampaignJumbotron'>
                <Wrapper>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ alignSelf: 'center' }}
                        >
                            <Text variant='h2' label={title} />
                            {subtitle && (
                                <Text
                                    variant='h5'
                                    label={subtitle}
                                    box={{ color: '#615A80', mt: 2 }}
                                />
                            )}
                            <Text
                                variant='subtitle1'
                                label={text}
                                html
                                box={{ color: '#766F90', mt: 7 }}
                            />

                            {callToAction?.label && (
                                <JumbotronCallToAction {...callToAction} />
                            )}
                            <Spacer variant={!desktop ? 'md' : 'lg'} />
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={12} md={1} />
                        </Hidden>
                        <Grid item xs={12} md={6}>
                            <Box position='relative'>
                                <img
                                    src={`${image}?auto=format&w=552&dpr=${dpr}`}
                                    alt='CampaignJumboImg'
                                    width='100%'
                                    style={{
                                        transform: desktop
                                            ? 'translateX(50px)'
                                            : 'translateX(0px)'
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Wrapper>
            </Box>
        </Box>
    )
}
