import { Box } from '@material-ui/core'
import React from 'react'

export interface AlertProps {
    text: string
}

export const Alert: React.FC<AlertProps> = ({ text }) => {
    return (
        <Box
            data-testid='Alert'
            style={{
                border: '2px solid #3A3160',
                padding: '18px 36px',
                color: '#3A3160',
                fontSize: 16,
                fontWeight: 700,
                margin: '10px 0px'
            }}
        >
            {text}
        </Box>
    )
}
