import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { CampaignFeature } from '@irishlife/ilgroupdesignsystem.organisms.campaign-feature'
import { getCampaignFeatureProps } from 'common/utils/geCampaignFeatureProps'

export interface CampaignSectionProps {
    alerts: AlertsProps
    campaignFeature: CampaignFeatureProps
}

export const CampaignSection: React.FC<CampaignSectionProps> = ({
    alerts,
    campaignFeature
}) => {

    const desktop = useDesktop()
    const campaignFeatureProps = getCampaignFeatureProps(campaignFeature)

    return (
        <>
            {campaignFeature.title && (
                <>
                    <Spacer variant={desktop ? 'lg' : 'md'} />
                    <CampaignFeature {...campaignFeatureProps} />
                </>
            )}
            {alerts && (
                <Wrapper>
                    <>
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                        <Alerts {...alerts} />
                    </>
                </Wrapper>
            )}
        </>
    )
}
