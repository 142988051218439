import { Grid } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import React from 'react'
import { Alert } from './Alert'

export interface AlertsProps {
    alerts: string[]
}

export const Alerts: React.FC<AlertsProps> = ({ alerts = [] }) => {
    return (
        <Wrapper>
            <Grid data-testid='Alerts' container>
                {alerts.map(alert => (
                    <Grid key={alert} item xs={12} md={8}>
                        <Alert text={alert} />
                    </Grid>
                ))}
            </Grid>
        </Wrapper>
    )
}
