import { Grid, makeStyles } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

export interface VideoProps {
    source?: string
    url?: string
    width?: number
    height?: number
    title: string
    heading: string
    text: string
}

const useStyles = makeStyles({
    videoTitle: {
        color: '#4DB7AB',
    },
    videoHeading: {
        fontSize: '40px',
    },
    videoResponsive: {
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: '0',
        '& > iframe': {
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            position: 'absolute',
        },
    },
})

export const Video: React.FC<VideoProps> = ({
    source,
    url,
    width,
    height,
    title,
    heading,
    text,
}) => {
    const classes = useStyles()
    return (
        <Grid
            data-testid='video'
            container={true}
            style={{ paddingTop: '85px' }}
        >
            {(title || text || heading) && (
                <Grid
                    md={6}
                    xs={12}
                    item={true}
                    style={{ paddingRight: '50px' }}
                >
                    {title && (
                        <Text
                            className={classes.videoTitle}
                            box={{ mb: 6 }}
                            label={title}
                            variant='overline'
                        />
                    )}
                    {heading && (
                        <Text
                            className={classes.videoHeading}
                            label={heading}
                            variant='h1'
                        />
                    )}
                    {text && (
                        <Text
                            label={text}
                            variant='body2'
                            color='textSecondary'
                            html={true}
                        />
                    )}
                </Grid>
            )}

            <Grid md={title || text || heading ? 6 : 12} xs={12} item={true}>
                {source && (
                    <div>
                        <video
                            height={height ? height : '100%'}
                            width={width ? width : '100%'}
                            controls
                        >
                            <source src={source} type='video/mp4' />
                        </video>
                    </div>
                )}
                {url && (
                    <div className={classes.videoResponsive}>
                        <iframe
                            width={width}
                            height={height}
                            data-src='https://www.youtube.com/embed/womtdV17aXw?rel=0'
                            data-cookiescript='accepted'
                            data-cookiecategory='marketing'
                            data-alt-img='https://img.youtube.com/vi/womtdV17aXw/maxresdefault.jpg'
                            title='YouTube video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        ></iframe>
                    </div>
                )}
            </Grid>
        </Grid>
    )
}
