import { JumbotronProps } from '@irishlifedigitalhub/ds.organisms.jumbotron'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { CampaignLandingRegular } from 'common/components/templates/CampaignLanding/CampaignLandingRegular'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CampaignLandingRegularPageProps {
    pageContext: any
}

export const CampaignLandingRegularPage: React.FC<CampaignLandingRegularPageProps> =
    ({ pageContext }) => {
        return (
            <CampaignLandingRegular
                {...getCampaignLandingRegularData(pageContext)}
            />
        )
    }

export default CampaignLandingRegularPage
export const getCampaignLandingRegularData = (data: any) => ({
    jumbotron: {
        ...getJumbotronData(data),
        image: data.campaign_jumbotron_image,
        text: data.campaign_jumbotron_text,
        title: data.campaign_jumbotron_title,
        subtitle: data.campaign_jumbotron_subtitle,
        variant: 'campaign' as JumbotronProps['variant'],
    },
    layout: {
        ...getLayoutData(data),
        footer: {
            ...getLayoutData(data).footer,
            regLine: data?.footer__regulatory_text?.[0].text,
        },
        jumbotronVariant: 'campaign' as JumbotronProps['variant'],
    },
    featureListHeading: data.feature_list_heading,
    featureListText: data.feature_list_text,
    campaignFeatures: data.features_list.map((item: any) => ({
        image: item.image,
        title: item.title,
        subtitle: item.subtitle,
        features: item.features,
        variant: item.variant,
        callToAction: {
            analyticsId: item.call_to_action__analytics_id,
            label: item.call_to_action__label,
            icon: item.call_to_action__icon,
            variant: item.call_to_action__variant,
            [`${item.call_to_action__iconlocation}Icon`]:
                item.call_to_action__icon,
            url: item.call_to_action__url,
        },
        cta: item.show_cta,
    })),
    video: {
        height: data.video__height,
        width: data.video__width,
        url: data.video__url,
        source: data.video__video,
        title: data.video__title,
        text: data.video__text,
    },
    campaignFeature: {
        title: data.campaign_feature_title,
        callToAction: {
            url: data.card_cta_0396ba7__cta_url,
            text: data.call_to_action__text,
            title: data.card_cta_0396ba7__cta_label,
            variant: data.card_cta_0396ba7__cta_variant,
            analyticsId: data.card_cta_0396ba7__cta_analytics_id,
            label: data.card_cta_0396ba7__cta_label,
        },
        text: data.campaign_feature_text,
        image: data.campaign_feature_image,
        variant: data.campaign_feature_variant,
    },

    alerts: {
        alerts: data.alerts__alerts?.map((alert: any) => alert.text),
    },
})
